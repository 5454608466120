import { removeAccents } from "./removeAccents";


export function removeSpecialCharacters(value: string) {
  if (value) {
    return removeAccents(value).replace(/[^\w\s]/g, "");
  }

  return value;
}


export function capitalizeText(value: string) {
  return value?.toLowerCase().split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ").trim();
}

export function formatAdobeText(value: string) {
  const _removeSpecialCharacters = removeSpecialCharacters(value);
  return capitalizeText(_removeSpecialCharacters);
}
