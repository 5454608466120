import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { catchError, map, retry } from 'rxjs';

import { amazon, environment } from '@envs/environment';
import { manejarError } from '../helpers';
import { GetSettingsInterface } from '../interfaces';


@Injectable({
  providedIn: 'root'
})
export class GlobalVarService {
  private readonly http = inject(HttpClient);

  showOutlet = signal(true);
  isPhone = signal(false);
  loadingText = signal('');
  warningToastText = signal('');
  #backButtonHeader = signal<boolean>(false);

  private enganche: string | number = "";
  private meses: string | number = "";
  private folioEcac: string | number = "";
  private cveSAP: string | number = "";
  private skuName: string | number = "";
  private noSerial: string | number = "";
  private correoElectronico: string | number = "";
  private folioCita: string | number = "";
  private datosDireccion: any = {};

  set setDatosdireccion(datos: any) {
    this.datosDireccion = datos
  }

  get backButtonHeader() {
    return this.#backButtonHeader.asReadonly();
  }

  get getDatosDireccion() {
    return this.datosDireccion
  }

  get getEnganche() {
    return this.enganche
  }
  set setEnganche(enganche: string) {
    this.enganche = enganche
  }
  get getMeses() {
    return this.meses
  }
  set setMeses(meses: string) {
    this.meses = meses
  }
  get getFolioEcac() {
    return this.folioEcac
  }
  set setFolioEcac(folioEcac: string) {
    this.folioEcac = folioEcac
  }
  get getCveSAP() {
    return this.cveSAP
  }
  set setCveSAP(cveSAP: string) {
    this.folioEcac = cveSAP
  }
  get getSkuName() {
    return this.skuName
  }
  set setSkuName(skuName: string) {
    this.folioEcac = skuName
  }
  get getNoSerial() {
    return this.noSerial
  }
  set setNoSerial(noSerial: string) {
    this.folioEcac = noSerial
  }
  get getCorreoElectronico() {
    return this.correoElectronico
  }
  set setCorreoElectronico(correoElectronico: string) {
    this.folioEcac = correoElectronico
  }
  get getFolioCita() {
    return this.folioCita
  }
  set setFolioCita(folioCita: string) {
    this.folioEcac = folioCita
  }

  guardaPasos(idPantalla: number) {
      const headers = new HttpHeaders({
        'x-api-key': environment.apiKey_actualizaPasos
      });
      const body = {
        token: sessionStorage.getItem('tokenTelcel'),
        numeroCelular: sessionStorage.getItem('numeroCelular'),
        payload: {
          "idPantalla": idPantalla
        }
      }
      return this.http.post<any>(
        `${amazon.apiUrlAmazon}/actualizaPaso`,
        body,
        { headers }
      ).pipe(
        retry(3),
        map((response) => {
          if (!response.process) throw response.message;
          return response
        }),
        catchError((error) => manejarError(error))
      );

  }

  getSettings() {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_settings
    });

    const idFlujo = sessionStorage.getItem('idFlujo')!;

    return this.http.get<GetSettingsInterface>(`${amazon.apiUrlAmazon}/obtenerConfiguracionesFront?idFlujo=${idFlujo}&nivelRiesgo=${sessionStorage.getItem('nivelRiesgo')}`,
      {
        headers
      },
    ).pipe(
      map((response) => response.responseObject)
    );
  }

  onClickHederButton(value = true) {
    this.#backButtonHeader.set(value);
  }
}
