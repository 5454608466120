import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AdobeTagManagerService {
  sendEvent(event: any) {
    try {
      const dataLayer = (window as any).dlMetri;

      if (dataLayer && typeof (dataLayer['push']) == 'function') {
        dataLayer.push(event);
        console.log('Evento ADOBE construido correctamente');
        console.log(event);
      }
    } catch (error) {
      console.log('Hubo un error en ADOBE Tag Manager');
      console.log(error);
    }
  }
}
